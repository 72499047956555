/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Slider from "react-slick";
import PropTypes from "prop-types";
import Menu from "./Menu";
import { withRouter } from "react-router-dom";
import Menumobile from "./Menumobile";

var Parser = require("html-react-parser");

import {
  fbAppId,
  baseUrl,
  appId,
  apiUrl,
  deliveryId,
  pickupId,
  cateringId,
  reservationId,
  dineinId,
} from "../Helpers/Config";
import {
  addressFormat,
  stripslashes,
  showPriceValue,
  timeToConv12,
  showLoader,
  hideLoader,
  showPriceValueNew,
  getSurchargesplitup,
} from "../Helpers/SettingHelper";

import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Moment from "moment";
var qs = require("qs");
import {
  GET_CORDERDETAIL,
  GET_PORDERDETAIL,
  GET_PRINTORDER,
  GET_ORDERHISTORY,
  GET_CATERINGCORDER,
  GET_CATERINGPORDER,
  GET_CATERINGHISTORY,
  GET_CATERINGPRINT,
  GET_ACTIVITYCOUNT,
  GET_RESERVATIONHISTORY,
} from "../../actions";

import cookie from "react-cookies";

import scotterImg from "../../common/images/scotter-icon.png";
import mapImg from "../../common/images/map-icon.png";
import productImg from "../../common/images/noimg-400x400.jpg";
class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      corderdetail: [],
      cartItems: [],
      cartData: [],
      cart_brktime_list: [],
      showitems: 10,
      showpitems: 10,
      showresvitems: 10,
      resviewmore: "none",
      order_all: 0,
      overall_orders: 0,
      catering_orders: 0,
      reservation_orders: 0,
      deliveryTakeaway_orders: 0,
      CateringReceiptDetails: [],
      CateringCartItems: [],
      reservationlist: [],
    };

    if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
      props.history.push("/");
    }
  }

  componentDidMount() {
    /* delivery current - past orders */
    var deliveryparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getCorderDetail(deliveryparams);

    var deliverypastparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getPorderDetail(deliverypastparams);
    $("#dvLoading").fadeOut(2000);

    /* catering current- past orders */
    var cateringparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      cateringId +
      "&limit=" +
      this.state.showitems;
    this.props.getCateringOrder(cateringparams);

    var cateringpastparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      cateringId +
      "&limit=" +
      this.state.showitems;
    this.props.getCateringPast(cateringpastparams);

    /* reservation orders */
    this.props.getReservationHistory(this.state.showresvitems);

    $("#dvLoading").fadeOut(2000);

    //Default Action
    $(".filter_tabsec").hide(); //Hide all content
    $("ul.web_order_typecls li:first").addClass("active").show(); //Activate first tab
    $(".filter_tabsec:first").show(); //Show first tab content

    //On Click Event
    $("ul.web_order_typecls li").click(function () {
      $("ul.web_order_typecls li").removeClass("active"); //Remove any "active" class
      $(this).addClass("active"); //Add "active" class to selected tab
      $(".filter_tabsec").hide(); //Hide all tab content
      var activeTab = $(this).find("a").attr("href"); //Find the rel attribute value to identify the active tab + content
      $(activeTab).fadeIn(); //Fade in the active content
      return false;
    });

    var order_tabs = cookie.load("orderTabs");
    if (order_tabs == "reservation") {
      $(".reservation_tab").trigger("click");
    }
    cookie.remove("orderTabs");

    this.getActivityCounts();
  }

  /* for order load more  button */
  loadcurrentItems() {
    var pageNext = this.state.showitems + 10;
    this.setState({ showitems: pageNext }, function () {
      this.loadcitems();
    });
  }
  loadpastItems() {
    var pagepNext = this.state.showpitems + 10;
    this.setState({ showpitems: pagepNext }, function () {
      this.loadpitems();
    });
  }

  loadcitems() {
    $(".load_more_data").append('<b class="gloading_img"></b>');
    var deliveryparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      deliveryId +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getCorderDetail(deliveryparams);
  }
  loadpitems() {
    $(".load_more_data1").append('<b class="gloading_img"></b>');
    var deliverypastparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      deliveryId +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getPorderDetail(deliverypastparams);
  }

  loadReservationItems() {
    showLoader("reserv-loader-div", "class");
    var pageNext = this.state.showresvitems + 10;
    this.setState({ showresvitems: pageNext }, function () {
      this.props.getReservationHistory(pageNext);
    });
  }

  getActivityCounts() {
    const inputKeys = [
      "catering_orders",
      "order_all",
      "overall_orders",
      "deliveryTakeaway_orders",
    ];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }

  /* catering load more button */
  cateringloadItems() {
    var pageNext = this.state.showitems + 10;
    this.setState({ showitems: pageNext }, function () {
      this.loadcateringitems();
    });
  }
  cateringpastItems() {
    var pagepNext = this.state.showpitems + 10;
    this.setState({ showpitems: pagepNext }, function () {
      this.loadcateringpitems();
    });
  }

  loadcateringitems() {
    $(".cateringloadmore").append('<b class="gloading_img"></b>');
    var cateringparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      cateringId +
      "&limit=" +
      this.state.showitems;
    this.props.getCateringOrder(cateringparams);
  }
  loadcateringpitems() {
    $(".cateringloadmore1").append('<b class="gloading_img"></b>');
    var cateringpastparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      cateringId +
      "&limit=" +
      this.state.showitems;
    this.props.getCateringPast(cateringpastparams);
  }

  componentWillReceiveProps(nextProps) {
    /*activity count -start */
    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (
          nextProps.activitycount[0].status &&
          nextProps.activitycount[0].result_set
        ) {
          this.setState({
            order_all: nextProps.activitycount[0].result_set.order_all,
            overall_orders:
              nextProps.activitycount[0].result_set.overall_orders,
            deliveryTakeaway_orders:
              nextProps.activitycount[0].result_set.deliveryTakeaway_orders,
            catering_orders:
              nextProps.activitycount[0].result_set.catering_orders,
            reservation_orders:
              nextProps.activitycount[0].result_set.reservation_orders,
          });
          $("#dvLoading").fadeOut(2000);
        }
      }
    } else {
      this.setState({
        order_all: 0,
        overall_orders: 0,
        catering_orders: 0,
        deliveryTakeaway_orders: 0,
        reservation_orders: 0,
      });
    }

    /*current orders */
    if (nextProps.corderdetail !== this.props.corderdetail) {
      if (nextProps.corderdetail && nextProps.corderdetail[0].status == "ok") {
        $("b").removeClass("gloading_img");
        $("#dvLoading").fadeOut(2000);
        this.setState({ corderdetail: nextProps.corderdetail[0].result_set });
        if (nextProps.corderdetail[0].common.total_records) {
          this.setState({
            totalcount: nextProps.corderdetail[0].common.total_records,
          });
        }
        if (
          this.state.showitems > nextProps.corderdetail[0].common.total_records
        ) {
          $(".load_more_data").hide();
        } else {
          $(".load_more_data").show();
        }
      } else {
        $(".load_more_data").hide();
      }
    }

    if (nextProps.porderdetail !== this.props.porderdetail) {
      if (nextProps.porderdetail && nextProps.porderdetail[0].status == "ok") {
        $("b").removeClass("gloading_img");
        $("#dvLoading").fadeOut(2000);
        this.setState({ porderdetail: nextProps.porderdetail[0].result_set });
        if (
          nextProps.porderdetail[0].common.total_records &&
          this.state.showpitems > nextProps.porderdetail[0].common.total_records
        ) {
          $(".load_more_data1").hide();
        } else {
          $(".load_more_data1").show();
        }
      } else {
        $(".load_more_data1").hide();
      }
    }

    if (nextProps.orderhistory !== this.props.orderhistory) {
      if (nextProps.orderhistory[0].status === "ok") {
        this.setState({
          CartItems: nextProps.orderhistory[0].result_set[0]["items"],
          ReceiptDetails: nextProps.orderhistory[0].result_set[0],
        });

        $("#dvLoading").fadeOut(5000);
        setTimeout(function () {
          $.magnificPopup.open({
            items: {
              src: ".receipt_popup",
            },
            type: "inline",
          });
        }, 1000);
      } else {
        this.setState({ ReceiptDetails: [] });
      }
    }

    if (nextProps.printorder !== this.props.printorder) {
      if (nextProps.printorder[0].status === "ok") {
        $("#dvLoading").fadeOut(2000);
        window.open(nextProps.printorder[0].pdf_url, "_blank");
      }
    }
    /* caterign current & past order */

    if (nextProps.cateringorder !== this.props.cateringorder) {
      if (
        nextProps.cateringorder[0].result_set &&
        nextProps.cateringorder[0].status == "ok"
      ) {
        $("b").removeClass("gloading_img");
        $("#dvLoading").fadeOut(2000);
        this.setState({
          cateringorderdetail: nextProps.cateringorder[0].result_set,
        });
        if (
          nextProps.cateringorder[0].common.total_records &&
          this.state.showitems > nextProps.cateringorder[0].common.total_records
        ) {
          $(".cateringloadmore").hide();
        }
      } else {
        $(".cateringloadmore").hide();
      }
    }

    if (nextProps.cateringpast !== this.props.cateringpast) {
      if (
        nextProps.cateringpast[0].result_set &&
        nextProps.cateringpast[0].status == "ok"
      ) {
        $("b").removeClass("gloading_img");
        $("#dvLoading").fadeOut(2000);
        this.setState({
          cateringpastdetail: nextProps.cateringpast[0].result_set,
        });
        if (
          nextProps.cateringpast[0].common.total_records &&
          this.state.showitems > nextProps.cateringpast[0].common.total_records
        ) {
          $(".cateringloadmore1").hide();
        }
      } else {
        $(".cateringloadmore1").hide();
      }
    }

    if (nextProps.cateringhistory !== this.props.cateringhistory) {
      if (nextProps.cateringhistory[0].status === "ok") {
        console.log(nextProps.cateringhistory);
        this.setState(
          {
            CateringCartItems:
              nextProps.cateringhistory[0].result_set[0]["items"],
            CateringReceiptDetails: nextProps.cateringhistory[0].result_set[0],
          },
          function () {
            this.makeBrkTimeList();
          }.bind(this)
        );

        $("#dvLoading").fadeOut(5000);
        setTimeout(function () {
          $.magnificPopup.open({
            items: {
              src: ".receipt_cateringpopup",
            },
            type: "inline",
          });
        }, 1000);
      } else {
        this.setState({ CateringReceiptDetails: [], CateringCartItems: [] });
      }
    }

    if (nextProps.cateringprint !== this.props.cateringprint) {
      if (nextProps.cateringprint[0].status === "ok") {
        $("#dvLoading").fadeOut(2000);
        window.open(nextProps.cateringprint[0].pdf_url, "_blank");
      }
    }

    if (nextProps.reservationlist !== this.state.reservationlist) {
      var displyTxt = "none";
      if (
        this.state.showresvitems < nextProps.reservationcommon.total_records
      ) {
        displyTxt = "inline-block";
      }
      hideLoader("reserv-loader-div", "class");
      this.setState({
        reservationlist: nextProps.reservationlist,
        resviewmore: displyTxt,
      });
    }
  }

  makeBrkTimeList() {
    var cartDetailsSate = this.state.CateringReceiptDetails;
    var cartItems = this.state.CateringCartItems;
    var cartBrkTmList = [];
    if (
      Object.keys(cartDetailsSate).length > 0 &&
      Object.keys(cartItems).length > 0
    ) {
      var breaktimeEnable = cartDetailsSate.order_breaktime_enable;
      var breaktimeCount = cartDetailsSate.order_breaktime_count;
      if (breaktimeEnable === "Yes" && parseInt(breaktimeCount) > 0) {
        var tempKeyArr = Array();
        var tempKey = 0;
        for (var key in cartItems) {
          var chkKey = cartItems[key].item_breaktime_indexflag;
          if (!(chkKey in tempKeyArr)) {
            tempKeyArr[chkKey] = tempKey;
            var tempItemArr = Array();
            var tempItemLst = [];
            var intVl = tempKey + 1;
            var time_lable = "Break " + intVl;
            tempItemArr["cart_detaile"] = {
              break_time_lable: time_lable,
              break_time_index: cartItems[key].item_breaktime_indexflag,
              break_time_start: cartItems[key].item_breaktime_started,
              break_time_end: cartItems[key].item_breaktime_ended,
            };
            tempItemLst[0] = cartItems[key];
            tempItemArr["cart_items"] = tempItemLst;
            cartBrkTmList[tempKey] = tempItemArr;
            tempKey++;
          } else {
            var extKey = tempKeyArr[chkKey];
            cartBrkTmList[extKey]["cart_items"].push(cartItems[key]);
          }
        }
      }
    }

    this.setState({ cart_brktime_list: cartBrkTmList });

    setTimeout(function () {
      if ($(".mCustomScrollbar").length > 0) {
        $(".mCustomScrollbar").mCustomScrollbar();
      }
    }, 800);
  }

  getReceipt(orderId) {
    $("#dvLoading").fadeIn();
    var params =
      "&customer_id=" + cookie.load("UserId") + "&order_id=" + orderId;
    this.props.getOrderHistory(params);
  }

  printReceipt(orderId, availabilityId) {
    $("#dvLoading").fadeIn();
    this.props.getPrintOrder(orderId, deliveryId);
  }

  /* CATERING VIEW & PRINT */

  getCateringReceipt(orderId) {
    $("#dvLoading").fadeIn();
    var historyparams =
      "&customer_id=" + cookie.load("UserId") + "&order_id=" + orderId;
    this.props.getCateringHistory(historyparams);
  }

  printcateringReceipt(orderId) {
    $("#dvLoading").fadeIn();
    this.props.getPrintOrder(orderId, cateringId);
  }

  getOrderItemData = (dataProp) => {
    var dataProp = dataProp !== undefined ? dataProp : Array();
    if (Object.keys(dataProp).length > 0) {
      return dataProp.map((item) => {
        const orderDate = Moment(item.order_date).format("DD/MM/YYYY");
        const orderTime = Moment(item.order_date).format("h:mm A");

        return (
          <div key={item.order_id} className="current_order">
            <div className="myacc_head_sec">
              <div className="head_left">
                <div className="head-group">
                  <h4>ORDER # - {item.order_local_no}</h4>
                </div>
              </div>
              <div className="head_right">
                <div className="head-group">
                  <h4>{item.status_name}</h4>
                </div>
              </div>
            </div>

            <div className="order_details_body">
              {item.order_availability_id !== dineinId && (
                <div className="order_no_deatails">
                  Order placed at :
                  {Moment(item.order_created_on).format("DD/MM/YYYY") +
                    " " +
                    Moment(item.order_created_on).format("h:mm A")}
                  <span>Pay by : {item.order_method_name}</span>
                </div>
              )}

              {item.order_availability_id === deliveryId && (
                <div className="delivery_total delivery_total_text">
                  <div className="delivery_total_left">
                    <img src={scotterImg} />
                    <h3>Order Handling By</h3>
                    <span>
                      {item.outlet_name !== "" && item.outlet_name !== null
                        ? stripslashes(item.outlet_name)
                        : ""}
                    </span>
                    <span>
                      {addressFormat(
                        item.outlet_unit_number1,
                        item.outlet_unit_number2,
                        item.outlet_address_line1,
                        item.outlet_address_line2,
                        item.outlet_postal_code
                      )}
                    </span>
                  </div>
                  <div className="delivery_total_left delivery_total_right">
                    <img src={mapImg} />
                    <h3>Delivery Location</h3>
                    <span>
                      {addressFormat(
                        item.order_customer_unit_no1,
                        item.order_customer_unit_no2,
                        item.order_customer_address_line1,
                        item.order_customer_address_line2,
                        item.order_customer_postal_code
                      )}
                    </span>
                  </div>
                </div>
              )}

              {item.order_availability_id !== deliveryId &&
                item.order_availability_id !== dineinId && (
                  <div className="delivery_total delivery_total_text pickup-order-div">
                    <div className="delivery_total_left">
                      <h3>Pickup Location</h3>
                      <span>
                        {item.outlet_name !== "" &&
                        item.outlet_name !== undefined
                          ? stripslashes(item.outlet_name)
                          : ""}
                      </span>
                      <span>
                        {addressFormat(
                          item.outlet_unit_number1,
                          item.outlet_unit_number2,
                          item.outlet_address_line1,
                          item.outlet_address_line2,
                          item.outlet_postal_code
                        )}
                      </span>
                    </div>
                  </div>
                )}

              {item.order_availability_id === dineinId && (
                <div className="delivery_total delivery_total_text pickup-order-div">
                  <div className="delivery_total_left">
                    <h3>Dine In Location</h3>
                    <span>
                      {item.outlet_name !== "" && item.outlet_name !== undefined
                        ? stripslashes(item.outlet_name)
                        : ""}
                    </span>
                    <span>
                      {addressFormat(
                        item.outlet_unit_number1,
                        item.outlet_unit_number2,
                        item.outlet_address_line1,
                        item.outlet_address_line2,
                        item.outlet_postal_code
                      )}
                    </span>
                  </div>
                </div>
              )}

              <div className="delivery_total delivery_total_number">
                {item.order_availability_id !== dineinId && (
                  <div className="delivery_total_left">
                    <h2>
                      {item.order_availability_id === deliveryId
                        ? "Delivery"
                        : "Pickup"}{" "}
                      Date
                    </h2>
                    <h4 className="checkoutDate">
                      {orderDate} {orderTime}
                    </h4>
                  </div>
                )}
                {item.order_availability_id === dineinId && (
                  <div className="delivery_total_left">
                    <h2>Dine In Date</h2>
                    <h4 className="checkoutDate">
                      {orderDate} {orderTime}
                    </h4>
                  </div>
                )}

                {item.order_availability_id === dineinId && (
                  <div className="delivery_total_right">
                    <h2>Table Number</h2>
                    <h4 className="checkoutDate">{item.order_table_number}</h4>
                  </div>
                )}

                {item.order_availability_id !== dineinId && (
                  <div className="delivery_total_left delivery_total_right">
                    <h2>
                      {item.order_availability_id === deliveryId
                        ? "Delivery"
                        : "Pickup"}{" "}
                      time
                    </h2>
                    <h4 className="checkoutTime">{orderTime}</h4>
                  </div>
                )}
              </div>
            </div>

            <div className="order_details_footer">
              <div className="order_amt">
                <div className="order_amt-left">
                  <h3>Order Amount</h3>
                </div>
                <div className="order_amt-right">
                  <h3 className="text-right">
                    <sup>$</sup>
                    {item.order_total_amount}
                  </h3>
                </div>
              </div>

              {/*onClick={this.printReceipt.bind(this, item.order_primary_id)} onClick={this.getReceipt.bind(this, item.order_id)}*/}

              {item.order_availability_id === pickupId &&
              item.order_payment_mode === "1" &&
              item.order_method_name.toLowerCase() === "cash" ? (
                <div className="order_btns">
                  <a
                    href="javascript:void(0)"
                    onClick={this.getReceipt.bind(this, item.order_id)}
                    className="button view_recipt button_full_view"
                    title=""
                  >
                    View Receipt
                  </a>
                </div>
              ) : (
                <div className="order_btns">
                  <a
                    href="javascript:void(0)"
                    onClick={this.printReceipt.bind(
                      this,
                      item.order_primary_id
                    )}
                    className="button print_invoice"
                    title=""
                  >
                    Print Invoice
                  </a>
                  <a
                    href="javascript:void(0)"
                    onClick={this.getReceipt.bind(this, item.order_id)}
                    className="button view_recipt"
                    title=""
                  >
                    View Receipt
                  </a>
                </div>
              )}
            </div>
          </div>
        );
      });
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  };

  /* catering order - start*/

  getCateringItemData = (dataProp) => {
    if (dataProp) {
      return dataProp.map((item) => {
        const orderDate = Moment(item.order_date).format("DD/MM/YYYY");
        const orderTime = Moment(item.order_date).format("h:mm A");

        return (
          <div key={item.order_id} className="current_order">
            <div className="myacc_head_sec">
              <div className="head_left">
                <div className="head-group">
                  <h4>ORDER # - {item.order_local_no}</h4>
                </div>
              </div>
              <div className="head_right">
                <div className="head-group">
                  <h4>{item.status_name}</h4>
                </div>
              </div>
            </div>

            <div className="order_details_body">
              <div className="order_no_deatails">
                Order placed at :
                {Moment(item.order_created_on).format("DD/MM/YYYY") +
                  " " +
                  Moment(item.order_created_on).format("h:mm A")}
                <span>Our Staff will contact you for order confirmation</span>
              </div>
              <div className="delivery_total delivery_total_text">
                <div className="delivery_total_left">
                  <img src={scotterImg} />
                  <h3>Order Handling By</h3>
                  <span>{stripslashes(item.order_availability_name)}</span>
                  <span>{stripslashes(item.order_customer_address_line1)}</span>
                </div>
                <div className="delivery_total_left delivery_total_right">
                  <img src={mapImg} />
                  <h3>Event Location</h3>
                  <span>
                    {stripslashes(item.order_customer_billing_address_line1)}
                  </span>
                </div>
              </div>
              <div className="delivery_total delivery_total_number">
                <div className="delivery_total_left">
                  <h2>Event Date</h2>
                  <h4 className="checkoutDate">{orderDate}</h4>
                </div>
                <div className="delivery_total_left delivery_total_right">
                  <h2>Event time</h2>
                  <h4 className="checkoutTime">{orderTime}</h4>
                </div>
              </div>
            </div>

            <div className="order_details_footer">
              <div className="order_amt">
                <div className="order_amt-left">
                  <h3>Order Amount</h3>
                </div>
                <div className="order_amt-right">
                  <h3 className="text-right">
                    <sup>$</sup>
                    {item.order_total_amount}
                  </h3>
                </div>
              </div>

              {/*onClick={this.printReceipt.bind(this, item.order_primary_id)} onClick={this.getReceipt.bind(this, item.order_id)}*/}

              <div className="order_btns">
                {/*<a href="javascript:void(0)" onClick={this.printcateringReceipt.bind(this, item.order_primary_id)}   className="button print_invoice" title="">Print Invoice</a>*/}

                <a
                  href="javascript:void(0)"
                  onClick={this.getCateringReceipt.bind(this, item.order_id)}
                  className="button view_recipt button_full_view"
                  title=""
                >
                  View Recipt
                </a>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  };

  reservStatus(statusTxt) {
    var returnTxt = "Pending";
    statusTxt = statusTxt !== "" ? parseInt(statusTxt) : 0;
    if (statusTxt === 2) {
      returnTxt = "Cancel";
    } else if (statusTxt === 1) {
      returnTxt = "Confirmed";
    } else if (statusTxt === 3) {
      returnTxt = "Arrived";
    } else if (statusTxt === 4) {
      returnTxt = "Not Arrived";
    } else if (statusTxt === 5) {
      returnTxt = "Completed";
    }

    return returnTxt;
  }

  showNoOfPax(adultCount, childCount) {
    var adultTxt =
      parseInt(adultCount) == 0
        ? ""
        : parseInt(adultCount) == 1
        ? adultCount + " Adult"
        : adultCount + " Adults";
    var childTxt =
      parseInt(childCount) == 0
        ? ""
        : parseInt(childCount) == 1
        ? childCount + " Child"
        : childCount + " Children";
    var paxText = adultTxt + " " + childTxt;

    return paxText;
  }

  /* reservation order - start*/
  getReservationItemData = (dataProp) => {
    if (Object.keys(dataProp).length > 0) {
      return dataProp.map((item) => {
        const orderDate = Moment(item.reservation_date).format("DD/MM/YYYY");
        var dataTmtxt =
          Moment(item.reservation_date).format("YYYY/MM/DD") +
          " " +
          item.reservation_start_time;
        const orderTime = Moment(dataTmtxt).format("h:mm A");

        return (
          <div
            key={item.reservation_id}
            className="current_order reserv-orderlist"
          >
            <div className="myacc_head_sec">
              <div className="head_left">
                <div className="head-group">
                  <h4>RESERVATION # - {item.reservation_local_order_id}</h4>
                </div>
              </div>
              <div className="head_right">
                <div className="head-group">
                  <h4>{this.reservStatus(item.reservation_status)}</h4>
                </div>
              </div>
            </div>

            <div className="order_details_body">
              <div className="delivery_total delivery_total_number">
                <div className="delivery_total_left">
                  <h2>Reservation Date & Time</h2>
                  <h4 className="checkoutDate">
                    {orderDate} {orderTime}
                  </h4>
                </div>
                <div className="delivery_total_left delivery_total_right">
                  <h2>NO OF PAX</h2>
                  <h4 className="checkoutTime">
                    {this.showNoOfPax(
                      item.reservation_no_of_adult_pax,
                      item.reservation_no_of_children_pax
                    )}
                  </h4>
                </div>
              </div>
              <div className="order_no_deatails">
                <h3>Outlet Name</h3>
                <p>{item.reservation_outlet_name}</p>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  };

  render() {
    return (
      <div className="myacc-main-div">
        <Header />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>My Account</p>
            <span>
              My Account Dashboard allows you to view your recent activities,
              check your reward points and update account information.
            </span>
          </div>
        </div>
        <div className="innersection_wrap myadmin_wrap">
          <div className="mainacc_menusec">
            {/* container div - start */}
            <div className="container">
              <div className="mainacc_menuout">
                <Menumobile />
              </div>
              <div className="mainacc_menucontent">
                <div className="main_tabsec">
                  <div className="order-tab-section">
                    <div className="mainacc_mobrow">
                      <div className="tab_sec main_tabsec_inner">
                        <div class="tab-content">
                          <div className="myacc_filter">
                            <div className="container-one acc-bg">
                              <div className="tab-content">
                                <div className="tab-pane account-tab-section fade in active">
                                  <div className="tab_mobrow main_tabin">
                                    <div className="">
                                      <div className="account_sec">
                                        <div className="accmenu_sec">
                                          <Menu />
                                        </div>
                                        <div className="accsec_right pad-0">
                                          <div
                                            className="tab_sec filter_tabsec ordertab1"
                                            id="ordertab1"
                                          >
                                            <ul className="nav nav-tabs text-center orders">
                                              <li className="active">
                                                <a
                                                  data-toggle="tab"
                                                  href="#tab-id-inn5"
                                                  aria-expanded="true"
                                                >
                                                  <span>Current Orders</span>
                                                </a>
                                              </li>
                                              <li className="">
                                                <a
                                                  data-toggle="tab"
                                                  href="#tab-id-inn6"
                                                  aria-expanded="false"
                                                >
                                                  <span>Past Orders</span>
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  class="reservation_tab"
                                                  data-toggle="tab"
                                                  href="#tab-id-inn9"
                                                  aria-expanded="false"
                                                >
                                                  <span>Reservation</span>
                                                </a>
                                              </li>
                                            </ul>
                                            <div className="tab-content inner-bdy">
                                              <div
                                                id="tab-id-inn5"
                                                className="tab-pane fade active in ordr-hdr"
                                              >
                                                <div className="tab_mobrow filter_tabin">
                                                  <div className="order-delivery">
                                                    <div className="ord-body">
                                                      <div className="cur-order-body">
                                                        <div className="myacc_order_details">
                                                          {this.getOrderItemData(
                                                            this.state
                                                              .corderdetail
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="load_more_div">
                                                  <button
                                                    className="load_more_data"
                                                    onClick={this.loadcurrentItems.bind(
                                                      this
                                                    )}
                                                    style={{ display: "none" }}
                                                  >
                                                    Load More
                                                  </button>
                                                </div>
                                              </div>
                                              <div
                                                id="tab-id-inn6"
                                                className="tab-pane fade ordr-hdr"
                                              >
                                                <div className="tab_mobrow filter_tabin">
                                                  <div className="pst-order-body">
                                                    <div className="myacc_order_details">
                                                      {this.getOrderItemData(
                                                        this.state.porderdetail
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="load_more_div">
                                                  <button
                                                    className="load_more_data1"
                                                    style={{ display: "none" }}
                                                    onClick={this.loadpastItems.bind(
                                                      this
                                                    )}
                                                  >
                                                    Load More
                                                  </button>
                                                </div>
                                              </div>
                                              <div
                                                id="tab-id-inn9"
                                                className="tab-pane fade"
                                              >
                                                <div className="tab_mobrow filter_tabin">
                                                  <div className="order-delivery">
                                                    <div className="ord-body">
                                                      <div className="cur-order-body">
                                                        <div className="myacc_order_details">
                                                          {this.getReservationItemData(
                                                            this.state
                                                              .reservationlist
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="load_more_div">
                                                  <div className="reserv-loader-div">
                                                    <button
                                                      className="load_more_resdata"
                                                      style={{
                                                        display:
                                                          this.state
                                                            .resviewmore,
                                                      }}
                                                      onClick={this.loadReservationItems.bind(
                                                        this
                                                      )}
                                                    >
                                                      Load More
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="tab_sec filter_tabsec"
                                          id="ordertab2"
                                        >
                                          <ul className="nav nav-tabs text-center">
                                            <li className="active">
                                              <a
                                                data-toggle="tab"
                                                href="#tab-id-inn7"
                                                aria-expanded="true"
                                              >
                                                <span>Current Orders</span>
                                              </a>
                                            </li>
                                            <li className="">
                                              <a
                                                data-toggle="tab"
                                                href="#tab-id-inn8"
                                                aria-expanded="false"
                                              >
                                                <span>Past Orders</span>
                                              </a>
                                            </li>
                                          </ul>
                                          <div className="tab-content">
                                            <div
                                              id="tab-id-inn7"
                                              className="tab-pane fade active in"
                                            >
                                              <div className="tab_mobrow filter_tabin">
                                                <div className="order-delivery">
                                                  <div className="ord-body">
                                                    <div className="cur-order-body">
                                                      <div className="myacc_order_details">
                                                        {this.getCateringItemData(
                                                          this.state
                                                            .cateringorderdetail
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="load_more_div">
                                                <button
                                                  className="cateringloadmore"
                                                  onClick={this.cateringloadItems.bind(
                                                    this
                                                  )}
                                                >
                                                  Load More
                                                </button>
                                              </div>
                                            </div>
                                            <div
                                              id="tab-id-inn8"
                                              className="tab-pane fade"
                                            >
                                              <div className="tab_mobrow filter_tabin">
                                                <div className="pst-order-body">
                                                  <div className="myacc_order_details">
                                                    {this.getCateringItemData(
                                                      this.state
                                                        .cateringpastdetail
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="load_more_div">
                                                <button
                                                  className="cateringloadmore1"
                                                  onClick={this.cateringpastItems.bind(
                                                    this
                                                  )}
                                                >
                                                  Load More
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* catering order detail - end */}

                                        {/* reservation tab -start */}
                                        <div
                                          className="tab_sec filter_tabsec reserv-order-main"
                                          id="ordertab3"
                                        >
                                          <div className="tab-content"></div>
                                        </div>
                                        {/* reservation - end */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* container div - end */}
          </div>
        </div>

        <Footer />

        <div
          id="receipt-popup"
          className="white-popup mfp-hide popup_sec receipt_popup catering_receipt_popup"
        >
          <div className="pouup_in">
            <Viewreceipt
              details={this.state.ReceiptDetails}
              cartItems={this.state.CartItems}
            />
          </div>
        </div>

        <div
          id="receipt-popup"
          className="white-popup mfp-hide popup_sec receipt_cateringpopup catering_receipt_popup"
        >
          <div className="pouup_in">
            <Viewcateringreceipt
              details={this.state.CateringReceiptDetails}
              cartItems={this.state.CateringCartItems}
              brktimeList={this.state.cart_brktime_list}
            />
          </div>
        </div>
        <div id="dvLoading"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var reshistoryArr = Array();
  var rescommonArr = Array();
  if (Object.keys(state.reservationhistory).length > 0) {
    if (state.reservationhistory[0].status === "ok") {
      reshistoryArr = state.reservationhistory[0].result_set;
      rescommonArr = state.reservationhistory[0].common;
    }
  }

  return {
    corderdetail: state.corderdetail,
    porderdetail: state.porderdetail,
    printorder: state.printorder,
    orderhistory: state.orderhistory,
    cateringorder: state.cateringorder,
    cateringpast: state.cateringpast,
    cateringhistory: state.cateringhistory,
    cateringprint: state.cateringprint,
    activitycount: state.activitycount,
    reservationlist: reshistoryArr,
    reservationcommon: rescommonArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCorderDetail: (deliveryparams) => {
      dispatch({ type: GET_CORDERDETAIL, deliveryparams });
    },
    getPorderDetail: (deliverypastparams) => {
      dispatch({ type: GET_PORDERDETAIL, deliverypastparams });
    },
    getPrintOrder: (orderprimaryId, availabilityId) => {
      dispatch({ type: GET_PRINTORDER, orderprimaryId, availabilityId });
    },
    getOrderHistory: (params) => {
      dispatch({ type: GET_ORDERHISTORY, params });
    },
    getCateringOrder: (cateringparams) => {
      dispatch({ type: GET_CATERINGCORDER, cateringparams });
    },
    getCateringPast: (cateringpastparams) => {
      dispatch({ type: GET_CATERINGPORDER, cateringpastparams });
    },
    getCateringHistory: (historyparams) => {
      dispatch({ type: GET_CATERINGHISTORY, historyparams });
    },
    getCateringPrint: (orderprimaryId) => {
      dispatch({ type: GET_CATERINGPRINT, orderprimaryId });
    },
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
    getReservationHistory: (limitTxt) => {
      dispatch({ type: GET_RESERVATIONHISTORY, limitTxt });
    },
  };
};

Orders.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Orders));

class Viewreceipt extends Component {
  constructor(props) {
    super(props);
  }

  getCartDetList(cartItems) {
    if (cartItems.length > 0) {
      return cartItems.map((item, index) => (
        <div className="cart_row oreder_itm_row" key={index}>
          <div className="col-sm-cls cart_left">
            <div className="cart_info">
              <h4>
                {stripslashes(item.item_name)} X {item.item_qty}
              </h4>
              <h4>
                {item.voucher_gift_name !== "" && item.voucher_gift_name != null
                  ? " Gift To: " + item.voucher_gift_name
                  : ""}
              </h4>
              {this.loadModifierItems(
                "Component",
                item.modifiers,
                item.set_menu_component
              )}
            </div>
            {item.item_specification !== "" && (
              <p className="help-block">
                {stripslashes(item.item_specification)}
              </p>
            )}
            {/*} 
         <p className ="help-block">Special instruction for individual product items ...</p>
         {*/}
          </div>
          <div className="col-sm-cls cart_right text-right">
            <div className="cart_price">
              <p>${parseFloat(item.item_total_amount).toFixed(2)}</p>
            </div>
          </div>
        </div>
      ));
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';
    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        html +=
          "<p><b>" +
          stripslashes(modName) +
          ":</b></p><p> " +
          stripslashes(modval) +
          "</p>";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);
        html +=
          "<p><b>" +
          comboName +
          ":</b></p><p> " +
          comboVal +
          " " +
          this.showComboModifiers(combo[i]["product_details"][0]["modifiers"]) +
          "</p>";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboPro = combos[r]["menu_product_name"];
        var comboQty = combos[r]["menu_product_qty"];
        var comboPrice = combos[r]["menu_product_price"];
        var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
        html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p>";
      }
      return html;
    }
    return "";
  }
  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "";
    if (lenMod > 0) {
      html = "<div > ";
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["order_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b></p><p> " +
          modval +
          newModValPrice +
          "</p>";
      }
      html += "</div>";
    }
    return html;
    console.log(html + "----------");
  }
  componentWillReceiveProps(nextProps) {}

  render() {
    const { details, cartItems } = this.props;
    if (details !== undefined) {
      return (
        <div className="Viewreceipt">
          <div className="tnk-you">
            <div className="tnk-header">
              <div className="tnk-detail">
                {details.order_availability_id !== dineinId && (
                  <div className="tnk-order">
                    <h3>Order # - {details.order_local_no}</h3>
                    <p>
                      Order placed at :{" "}
                      {Moment(details.order_created_on).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                      <br></br>Pay by : {details.order_method_name}
                    </p>
                  </div>
                )}
                {details.order_availability_id === dineinId && (
                  <div className="tnk-order">
                    <h3>Order # - {details.order_local_no}</h3>
                    <p>Pay by : {details.order_method_name}</p>
                  </div>
                )}
                <h2>YOUR ORDER DETAILS</h2>
              </div>
            </div>
            <div className="tnk-delivery">
              {details.order_availability_id === deliveryId && (
                <div className="delivery_total">
                  <div className="delivery_total_left">
                    <img src={scotterImg} />
                    <h3>Delivery From</h3>
                    <span>
                      {details.outlet_name !== "" &&
                      details.outlet_name !== undefined
                        ? stripslashes(details.outlet_name)
                        : ""}
                    </span>
                    <span>
                      {addressFormat(
                        details.outlet_unit_number1,
                        details.outlet_unit_number2,
                        details.outlet_address_line1,
                        details.outlet_address_line2,
                        details.outlet_postal_code
                      )}
                    </span>
                  </div>
                  <div className="delivery_total_left delivery_total_right">
                    <img src={mapImg} />
                    <h3>Deliver TO</h3>
                    <span>{details.order_customer_address_line1}</span>{" "}
                    <span>Singapore {details.order_customer_postal_code}</span>{" "}
                    <span>
                      {details.order_customer_unit_no1 != "" &&
                      details.order_customer_unit_no2 != ""
                        ? details.order_customer_unit_no1 +
                          "-" +
                          details.order_customer_unit_no2
                        : ""}
                    </span>
                  </div>
                </div>
              )}

              {details.order_availability_id !== deliveryId &&
                details.order_availability_id !== dineinId && (
                  <div className="delivery_total pickup-order-div">
                    <div className="delivery_total_left">
                      <h3>Pickup Location</h3>
                      <span>
                        {details.outlet_name !== "" &&
                        details.outlet_name !== undefined
                          ? stripslashes(details.outlet_name)
                          : ""}
                      </span>
                      <span>
                        {addressFormat(
                          details.outlet_unit_number1,
                          details.outlet_unit_number2,
                          details.outlet_address_line1,
                          details.outlet_address_line2,
                          details.outlet_postal_code
                        )}
                      </span>
                    </div>
                  </div>
                )}

              {details.order_availability_id === dineinId && (
                <div className="delivery_total pickup-order-div">
                  <div className="delivery_total_left">
                    <h3>Dine In Location</h3>
                    <span>
                      {details.outlet_name !== "" &&
                      details.outlet_name !== undefined
                        ? stripslashes(details.outlet_name)
                        : ""}
                    </span>
                    <span>
                      {addressFormat(
                        details.outlet_unit_number1,
                        details.outlet_unit_number2,
                        details.outlet_address_line1,
                        details.outlet_address_line2,
                        details.outlet_postal_code
                      )}
                    </span>
                  </div>
                </div>
              )}
              {details.order_availability_id !== dineinId && (
                <div className="delivery_total delivery_total_number">
                  <div className="delivery_total_left">
                    <h2>
                      {details.order_availability_id === deliveryId
                        ? "Delivery"
                        : "Pickup"}{" "}
                      Date
                    </h2>
                    <h4 className="checkoutDate">
                      {Moment(details.order_date).format("DD-MM-YYYY")}
                    </h4>
                  </div>
                  <div className="delivery_total_left delivery_total_right">
                    <h2>
                      {details.order_availability_id === deliveryId
                        ? "Delivery"
                        : "Pickup"}{" "}
                      time
                    </h2>
                    <h4 className="checkoutTime">
                      {Moment(details.order_date).format("hh:mm A")}
                    </h4>
                  </div>
                </div>
              )}

              {details.order_availability_id === dineinId && (
                <div className="delivery_total delivery_total_number">
                  <div className="delivery_total_left">
                    <h2>Dine In Date</h2>
                    <h4 className="checkoutDate">
                      {Moment(details.order_date).format("DD-MM-YYYY hh:mm A")}
                    </h4>
                  </div>
                  <div className="delivery_total_right">
                    <h2>Table Number</h2>
                    <h4 className="checkoutDate">
                      {details.order_table_number}
                    </h4>
                  </div>
                </div>
              )}

              <div className="order-items-maindiv">
                {this.getCartDetList(cartItems)}
              </div>

              <div className="cart_footer tnk_cart_footer">
                <div className="cart_row">
                  <p className="text-uppercase">SUBTOTAL</p>
                  <span>${details.order_sub_total}</span>
                </div>
                {parseFloat(details.order_discount_amount) > 0 && (
                  <div className="cart_row">
                    <p className="text-uppercase">
                      {details.order_discount_type === "redeem"
                        ? "Discount (-)"
                        : "Promocode (-)"}
                    </p>
                    <span>${details.order_discount_amount}</span>
                  </div>
                )}
                {parseFloat(details.order_delivery_charge) > 0 && (
                  <div className="cart_row">
                    <p className="text-uppercase">Delivery Charges</p>
                    <span>
                      $
                      {(
                        parseFloat(details.order_delivery_charge) +
                        parseFloat(details.order_additional_delivery)
                      ).toFixed(2)}
                    </span>
                  </div>
                )}

                {parseFloat(details.order_service_charge_amount) > 0 && (
                  <div className="cart_row">
                    {parseFloat(details.order_service_charge) > 0 ? (
                      <p className="text-uppercase">
                        {details.order_servicecharge_displaylabel != "" &&
                        details.order_servicecharge_displaylabel != null
                          ? details.order_servicecharge_displaylabel
                          : "Service Charge"}{" "}
                        ({parseFloat(details.order_service_charge)}%)
                      </p>
                    ) : (
                      <p className="text-uppercase">
                        {details.order_servicecharge_displaylabel != "" &&
                        details.order_servicecharge_displaylabel != null
                          ? details.order_servicecharge_displaylabel
                          : "Service Charge"}
                      </p>
                    )}
                    <span>
                      $
                      {parseFloat(details.order_service_charge_amount).toFixed(
                        2
                      )}
                    </span>
                  </div>
                )}

                {details.order_tax_calculate_amount > 0 && (
                  <div className="cart_row gst-row">
                    <p className="text-uppercase">
                      GST({details.order_tax_charge}%)
                    </p>
                    <span>${details.order_tax_calculate_amount}</span>
                  </div>
                )}
                {details.order_tax_calculate_amount_inclusive > 0 && (
                  <div className="cart_row gst-row">
                    <p className="text-uppercase">
                      GST Inclusive({details.order_tax_charge_inclusive}%)
                    </p>
                    <span>${details.order_tax_calculate_amount_inclusive}</span>
                  </div>
                )}
                <div className="cart_row cart_footer_totrow grant-total-cls">
                  <p className="text-uppercase">Total</p>
                  <span>${details.order_total_amount}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

class Viewcateringreceipt extends Component {
  constructor(props) {
    super(props);
  }

  getCartDetList(details, cartItems, brktimeList) {
    var cartDetailsSate = details;
    var cartBrktimeLst = brktimeList;
    if (Object.keys(cartDetailsSate).length > 0) {
      var breaktimeEnable = cartDetailsSate.order_breaktime_enable;
      if (breaktimeEnable === "Yes" && Object.keys(cartBrktimeLst).length > 0) {
        return this.cartBrkTimeList(details, cartItems, brktimeList);
      } else {
        return this.cartListOnly(details, cartItems, brktimeList);
      }
    }
  }

  cartListOnly(details, cartItems, brktimeList) {
    return (
      <div className="panel panel-default">
        <div
          className="panel-heading act"
          role="tab"
          id="headingTb0"
          onClick={this.handlerActTab.bind(this, 0)}
        >
          <h4 className="panel-title">
            <a
              role="button"
              data-toggle="collapse"
              data-parent="#accordion"
              aria-expanded="true"
              href="#collapse0"
              className=""
            >
              Order Item Details
            </a>
          </h4>
        </div>
        <div
          id="collapse0"
          className="panel-collapse collapse mCustomScrollbar in"
          aria-expanded="true"
          href="#collapse0"
        >
          <div className="panel-body">{this.cartItemlist(cartItems)}</div>
        </div>
      </div>
    );
  }

  cartBrkTimeList(details, cartItems, brktimeList) {
    var cartBrktimeLst = brktimeList;

    const cartBrktmhtml = cartBrktimeLst.map((cartBrk, indx) => (
      <div className="panel panel-default" key={indx}>
        <div
          className={indx === 0 ? "panel-heading act" : "panel-heading"}
          role="tab"
          id={"headingTb" + indx}
          onClick={this.handlerActTab.bind(this, indx)}
        >
          <h4 className="panel-title">
            <a
              role="button"
              data-toggle="collapse"
              data-parent="#accordion"
              aria-expanded={indx === 0 ? "true" : "false"}
              href={"#collapse" + indx}
              className={indx === 0 ? "" : "collapsed"}
            >
              {cartBrk["cart_detaile"].break_time_lable} :{" "}
              <span className="hr">
                {timeToConv12(cartBrk["cart_detaile"].break_time_start)}
              </span>
            </a>
          </h4>
        </div>
        <div
          id={"collapse" + indx}
          className={
            indx === 0
              ? "panel-collapse collapse mCustomScrollbar in"
              : "panel-collapse collapse mCustomScrollbar"
          }
          aria-expanded={indx === 0 ? "true" : "false"}
          href={"#collapse" + indx}
        >
          <div className="products_info">
            {this.cartItemlist(cartBrk["cart_items"])}
          </div>
        </div>
      </div>
    ));
    return cartBrktmhtml;
  }

  handlerActTab(indx) {
    var ariaAxp = $("#headingTb" + indx)
      .find("a")
      .attr("aria-expanded");
    if (ariaAxp === "true") {
      $("#headingTb" + indx).addClass("act");
    } else {
      $("#headingTb" + indx).removeClass("act");
    }
    $(".panel-heading")
      .not($("#headingTb" + indx))
      .removeClass("act");
  }
  checkSettup(setup, type) {
    let count = 0;
    setup.map((settup) => {
      if (settup.os_setup_type === type) {
        count++;
      }
    });
    if (count > 0) {
      return true;
    } else {
      return false;
    }
  }
  settuptotalPrice(setup, type) {
    let totalPrice = 0;
    if (type === "Equipment") {
      setup.map((settup) => {
        totalPrice += parseFloat(settup.oe_equipment_total_price);
      });
    } else {
      setup.map((settup) => {
        if (settup.os_setup_type === type) {
          totalPrice += parseFloat(settup.os_setup_total_price);
        }
      });
    }
    return totalPrice;
  }
  loadSettup(setup, type) {
    let check_settup = this.checkSettup(setup, type);
    if (type === "Equipment") {
      return setup.map((settup, index) => {
        return (
          <p key={index}>
            {settup.oe_equipment_qty} X {settup.oe_equipment_description} (+
            {showPriceValueNew(settup.oe_equipment_price)})
          </p>
        );
      });
    } else if (type === "Addons") {
      return setup.map((settup, index) => {
        return (
          <div className="pkgslist_additionrow" key={index}>
            <div className="pkg-list-lhs">
              <label>{settup.as_setup_title}</label>
              <div>
                <span className="price">
                  {showPriceValueNew(settup.as_setup_amount)}
                </span>
              </div>
            </div>
            <div className="pkgslist_subaddition">
              {this.loadAddonValues(settup.addons_setup_values)}
            </div>
          </div>
        );
      });
    } else {
      if (check_settup) {
        return setup.map((settup, index) => {
          if (settup.os_setup_type === type) {
            return (
              <p key={index}>
                {settup.os_setup_name} (
                {showPriceValueNew(settup.os_setup_total_price)}){" "}
              </p>
            );
          }
        });
      }
    }
  }
  loadAddonValues(AddonValues) {
    return AddonValues.map((addonval, index) => {
      return (
        <p key={index}>
          {addonval.asv_setup_val_title} ({addonval.asv_setup_val_qty}X)
        </p>
      );
    });
  }
  loadModifierItems(
    itemType,
    modifiers,
    combo,
    cart_item_qty,
    cart_item_unit_price,
    cart_item_id,
    cart_item_product_id,
    cart_item_min_qty
  ) {
    var len = modifiers.length;
    if (combo !== undefined) {
      var comboLen = combo.length;
    }
    var html = "";

    var temp_html = "";

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modVlPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModVlPrice = modVlPrice > 0 ? " (+" + modVlPrice + ")" : "";
        temp_html +=
          "<p><b>" +
          modName +
          ":</b></p> <p>" +
          modval +
          newModVlPrice +
          "</b></p> ";
      }

      html += temp_html + "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (itemType === "Component" && comboLen > 0) {
      for (var j = 0, length = comboLen; j < length; j++) {
        var comboName = combo[j]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[j]["product_details"]);
        if (combo[j]["product_details"][0]["modifiers"].length) {
          html += "<p><b>" + comboName + ":</b> </p><p>" + comboVal + "</p> ";
        } else {
          html += "<p><b>" + comboName + ":</b> </p><p>" + comboVal + "</p> ";
        }
      }
      var reactElement1 = Parser(html);
      return reactElement1;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["menu_product_qty"]) > 0) {
          var comboPro = combos[r]["menu_product_name"];
          var comboQty = combos[r]["menu_product_qty"];
          var comboPrice = combos[r]["menu_product_price"];
          var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
          html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
          if (combos[r].modifiers.length > 0) {
            html += this.showComboModifiersValues(combos[r]);
          }
        }
      }
      return html;
    }
    return "";
  }
  showComboModifiersValues(product_details) {
    var mod_length = product_details.modifiers.length;
    var html = " ";
    for (var m = 0, leng = mod_length; m < leng; m++) {
      var cart_mod_name = product_details.modifiers[m].order_modifier_name;
      var mod_val_leng = product_details.modifiers[m].modifiers_values.length;
      html +=
        "<div class='modifier_values_list_wrap'><p>" + cart_mod_name + "</p>";
      html += "<div class='modifier_values_list'>";
      for (var v = 0, lenth = mod_val_leng; v < lenth; v++) {
        var cart_modifier_name =
          product_details.modifiers[m].modifiers_values[v].order_modifier_name;
        var cart_modifier_price =
          product_details.modifiers[m].modifiers_values[v].order_modifier_price;
        cart_modifier_price =
          parseInt(cart_modifier_price) > 1
            ? "(" + showPriceValueNew(cart_modifier_price) + ")"
            : "";
        html +=
          "<p>" + cart_modifier_name + "&nbsp;" + cart_modifier_price + "</p>";
      }

      html += "</div></div>";
    }

    return html;
  }

  cartItemlist(itemLst) {
    if (Object.keys(itemLst).length > 0) {
      const cartlsthtml = itemLst.map((cartLst, indx1) => (
        <div key={indx1} className="cart-package-info">
          {/* package div start */}
          <div className="products_info_item product-details">
            <div className="cart_left">
              <h4>{cartLst.item_name}</h4>
              <div class="products_info_options">
                <div className="products_info_options_wrap">
                  {this.loadModifierItems(
                    "Component",
                    cartLst.modifiers,
                    cartLst.set_menu_component,
                    cartLst.item_qty,
                    cartLst.item_unit_price,
                    cartLst.item_id,
                    cartLst.item_product_id,
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="cart_right text-right">
              <span className="cart_indvl_price">
                {showPriceValueNew(cartLst.item_total_amount)}
              </span>
              <span className="cart_indvl_price"> {cartLst.item_qty} pax </span>
            </div>
          </div>
          <div className="cart-settup-list">
            <ul>
              {this.checkSettup(cartLst.setup, "Buffet") && (
                <li>
                  <div className="pkg-list-lhs">
                    <label>Buffet:</label>
                    <div>
                      <span className="price">
                        {showPriceValueNew(
                          this.settuptotalPrice(cartLst.setup, "Buffet")
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="pkgslist_addition">
                    {this.loadSettup(cartLst.setup, "Buffet")}
                  </div>
                </li>
              )}
              {this.checkSettup(cartLst.setup, "Delivery") && (
                <li>
                  <div className="pkg-list-lhs">
                    <label>Delivery:</label>
                    <div>
                      <span className="price">
                        {showPriceValueNew(
                          this.settuptotalPrice(cartLst.setup, "Delivery")
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="pkgslist_addition">
                    {this.loadSettup(cartLst.setup, "Delivery")}
                  </div>
                </li>
              )}
              {this.checkSettup(cartLst.setup, "Self Collection") && (
                <li>
                  <div className="pkg-list-lhs">
                    <label>Self Collection:</label>
                    <div>
                      <span className="price">
                        {showPriceValueNew(
                          this.settuptotalPrice(
                            cartLst.setup,
                            "Self Collection"
                          )
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="pkgslist_addition">
                    {this.loadSettup(cartLst.setup, "Self Collection")}
                  </div>
                </li>
              )}
              {cartLst.equipment !== "" &&
                cartLst.equipment !== undefined &&
                cartLst.equipment.length > 0 && (
                  <li>
                    <div className="pkg-list-lhs">
                      <label>Equipment:</label>
                      <div>
                        <span className="price">
                          {showPriceValueNew(
                            this.settuptotalPrice(
                              cartLst.equipment,
                              "Equipment"
                            )
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="pkgslist_addition">
                      {this.loadSettup(cartLst.equipment, "Equipment")}
                    </div>
                  </li>
                )}
              {cartLst.addons_setup !== "" &&
                cartLst.addons_setup !== undefined &&
                cartLst.addons_setup.length > 0 && (
                  <li>
                    <label>ADD-ONS:</label>
                    <div className="pkgslist_addition">
                      {this.loadSettup(cartLst.addons_setup, "Addons")}
                    </div>
                  </li>
                )}
            </ul>
          </div>
        </div>
      ));
      return cartlsthtml;
    }
  }

  showModifiers(mdfLst) {
    if (Object.keys(mdfLst).length > 0) {
      const mdflsthtml = mdfLst.map((mdfLt, indx3) => (
        <div key={indx3} className="package-list">
          <p className="one">{mdfLt.order_modifier_name}</p>
          <p className="two">{this.showMdfValues(mdfLt.modifiers_values)}</p>
        </div>
      ));
      return mdflsthtml;
    }
  }

  showMdfValues(mdfValLst) {
    var mdfValueText = "";
    if (Object.keys(mdfValLst).length > 0) {
      var tempVl = Object.keys(mdfValLst).length - 1;
      for (var key in mdfValLst) {
        var cartmdfprice =
          parseFloat(mdfValLst[key].order_modifier_price) > 0
            ? " ( " + showPriceValue(mdfValLst[key].order_modifier_price) + " )"
            : "";
        mdfValueText +=
          parseInt(tempVl) === parseInt(key)
            ? stripslashes(mdfValLst[key].order_modifier_name) + cartmdfprice
            : stripslashes(mdfValLst[key].order_modifier_name) +
              cartmdfprice +
              ", ";
      }
    }
    return mdfValueText;
  }

  addonsSetupLst(cartLstDat) {
    var addonsSet = cartLstDat.addons_setup;
    const addonhtml = addonsSet.map((addonSt, indx4) => (
      <div key={indx4} className="row addon_list">
        <div className="col-sm-6 col-xs-9">
          <h5>
            <a href="javascript:void(0)">
              <span className="title">{addonSt.as_setup_title}</span>
            </a>
          </h5>
          {this.addonsSetupValues(addonSt.addons_setup_values)}
        </div>
        <div className="col-sm-6 col-xs-3">
          {/*<div className="amt">
                      <span>{showPriceValue(addonSt.as_setup_amount)}</span>
                  </div>*/}
        </div>
      </div>
    ));
    return addonhtml;
  }

  addonsSetupValues(addonsVal) {
    if (Object.keys(addonsVal).length > 0) {
      const addonValhtml = addonsVal.map((addonVl, indx5) => (
        <p key={indx5}>
          {addonVl.asv_setup_val_title} ({addonVl.asv_setup_val_qty}X{" "}
          {parseFloat(addonVl.asv_setup_val_price) > 0
            ? showPriceValue(addonVl.asv_setup_val_price)
            : ""}
          )
        </p>
      ));
      return addonValhtml;
    }
  }

  setupDet(cartLstDat) {
    var setupDt = cartLstDat.setup;
    var setupLbl = "";
    var setupPrice = 0;

    for (var key in setupDt) {
      setupLbl = setupDt[key].os_setup_type;
      setupPrice += parseFloat(setupDt[key].os_setup_total_price);
    }

    return (
      <div className="buffet_setup">
        <div className="row">
          <div className="col-sm-6 col-xs-9">
            <h5>
              <a href="javascript:void(0)">
                <span className="title">{setupLbl} Setup</span>
              </a>
            </h5>
          </div>
          <div className="col-sm-6 col-xs-3">
            {/*<div className="amt">
              <span>{showPriceValue(setupPrice)}</span>
          </div>*/}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-10 col-xs-10">{this.setupList(setupDt)}</div>
        </div>
      </div>
    );
  }

  setupList(setupDats) {
    if (Object.keys(setupDats).length > 0) {
      const setupDathtml = setupDats.map((setupVl, indx6) => (
        <p key={indx6}>
          {setupVl.os_setup_name} : {setupVl.os_setup_description}{" "}
          {parseFloat(setupVl.os_setup_total_price) > 0
            ? " ( " + showPriceValue(setupVl.os_setup_total_price) + " )"
            : ""}
        </p>
      ));
      return setupDathtml;
    }
  }

  specialNotes(cartLstDat) {
    var special_not = cartLstDat.item_specification;

    return (
      <div className="buffet_setup" style={{ paddingTop: "0px" }}>
        <div className="row">
          <div className="col-sm-6 col-xs-8">
            <h5>
              <a href="">
                <span className="title">Special Notes</span>
              </a>
            </h5>
          </div>
          <div className="col-sm-6 col-xs-4"></div>
        </div>
        <div className="row">
          <div className="col-sm-10 col-xs-10">
            <p>{special_not}</p>
          </div>
        </div>
      </div>
    );
  }

  cartHallData(details, cartItems, brktimeList) {
    var cartDetailsMn = details;
    if (Object.keys(cartDetailsMn).length > 0) {
      if (cartDetailsMn.order_venue_type === "hall") {
        return (
          <div className="panel panel-default">
            <div
              className="panel-heading"
              role="tab"
              id="headingTbH1"
              onClick={this.handlerActTab.bind(this, "H1")}
            >
              <h4 className="panel-title">
                <a
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  aria-expanded="false"
                  href="#collapseH1"
                  className="collapsed"
                >
                  Catering Hall Details
                </a>
              </h4>
            </div>
            <div
              id="collapseH1"
              className="panel-collapse fixed-height-cls collapse mCustomScrollbar"
              aria-expanded="false"
              href="#collapseH1"
            >
              <div className="panel-body">
                <div className="buffet_setup" style={{ padding: "0px" }}>
                  <div className="row">
                    <div className="col-sm-6 col-xs-8">
                      <h5>
                        <a href="javascript:void(0)">
                          <span className="title">
                            {cartDetailsMn.order_hall_name}
                          </span>
                        </a>
                      </h5>
                    </div>
                    <div className="col-sm-6 col-xs-4">
                      <div className="amt">
                        <span>
                          {showPriceValue(cartDetailsMn.order_hall_charges)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }

  render() {
    const { details, cartItems, brktimeList } = this.props;
    let order_kitchen_remarks = {};
    if (details.order_kitchen_remarks) {
      order_kitchen_remarks = JSON.parse(details.order_kitchen_remarks);
    }
    let {
      sur_deliverysetup_fee,
      sur_ear_deliverysetup_fee,
      sur_lat_teardown_fee,
      sur_deliverysetup_fee_name,
      sur_ear_deliverysetup_fee_name,
    } = getSurchargesplitup(
      details.order_subcharge_amount,
      order_kitchen_remarks
    );
    return (
      <div className="Viewreceipt">
        <div className="tnk-you">
          <div className="tnk-header">
            <div className="tnk-detail">
              <h2>YOUR ORDER DETAILS</h2>
              <div className="tnk-order">
                <h3>Order # - {details.order_local_no}</h3>
                <p>
                  Order placed at :{" "}
                  {Moment(details.order_created_on).format(
                    "DD-MM-YYYY hh:mm A"
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="tnk-delivery">
            <div className="delivery_total">
              <div className="delivery_total_left">
                <img src={scotterImg} />
                <h3>Catering Handling By</h3>{" "}
                <span>
                  {details.outlet_name !== "" &&
                  details.outlet_name !== undefined
                    ? stripslashes(details.outlet_name)
                    : ""}
                </span>
                <span>
                  {addressFormat(
                    details.outlet_unit_number1,
                    details.outlet_unit_number2,
                    details.outlet_address_line1,
                    details.outlet_address_line2,
                    details.outlet_postal_code
                  )}
                </span>{" "}
              </div>
              <div className="delivery_total_left delivery_total_right">
                <img src={mapImg} />
                <h3>Catering Location</h3>
                {details.order_hall_name !== "" ? (
                  <span>{details.order_hall_name}</span>
                ) : (
                  ""
                )}
                <span>
                  {addressFormat(
                    details.order_customer_unit_no1,
                    details.order_customer_unit_no2,
                    details.order_customer_address_line1,
                    details.order_customer_address_line2,
                    details.order_customer_postal_code
                  )}
                </span>
              </div>
            </div>

            <div className="delivery_total delivery_total_number">
              <div className="delivery_total_left">
                <h2>Event Date</h2>
                <h4 className="checkoutDate">
                  {Moment(details.order_date).format("DD-MM-YYYY")}
                </h4>
              </div>
              <div className="delivery_total_left delivery_total_right">
                <h2>Event time</h2>
                <h4 className="checkoutTime">
                  {Moment(details.order_date).format("hh:mm A")}
                </h4>
              </div>
            </div>

            <div className="order_details">
              <div
                className="panel-group"
                id="accordion"
                role="tablist"
                aria-multiselectable="true"
              >
                {this.getCartDetList(details, cartItems, brktimeList)}
                {this.cartHallData(details, cartItems, brktimeList)}
              </div>
            </div>

            <div className="cart_footer tnk_cart_footer">
              <div className="cart_row">
                <p className="text-uppercase">Subtotal</p>

                <span>
                  {parseFloat(details.order_sub_total) > 0
                    ? showPriceValueNew(details.order_sub_total)
                    : ""}
                </span>
              </div>

              {parseFloat(details.order_discount_amount) > 0 && (
                <div className="cart_row">
                  <p className="text-uppercase">
                    {details.order_discount_type === "redeem"
                      ? "Discount (-)"
                      : "Promocode (-)"}
                  </p>
                  <span>
                    {showPriceValueNew(details.order_discount_amount)}
                  </span>
                </div>
              )}

              {(parseFloat(details.order_delivery_charge) > 0 ||
                parseFloat(details.order_additional_delivery) > 0) && (
                <div className="cart_row">
                  <p className="text-uppercase">Location surcharge</p>
                  <span>
                    {showPriceValueNew(
                      parseFloat(details.order_delivery_charge) +
                        parseFloat(details.order_additional_delivery)
                    )}
                  </span>
                </div>
              )}

              {parseFloat(details.order_service_charge_amount) > 0 && (
                <div className="cart_row">
                  {parseFloat(details.order_service_charge) > 0 ? (
                    <p className="text-uppercase">
                      {details.order_servicecharge_displaylabel != "" &&
                      details.order_servicecharge_displaylabel != null
                        ? details.order_servicecharge_displaylabel
                        : "Service Charge"}{" "}
                      ({parseFloat(details.order_service_charge)}%)
                    </p>
                  ) : (
                    <p className="text-uppercase">
                      {details.order_servicecharge_displaylabel != "" &&
                      details.order_servicecharge_displaylabel != null
                        ? details.order_servicecharge_displaylabel
                        : "Service Charge"}
                    </p>
                  )}
                  <span>
                    $
                    {parseFloat(details.order_service_charge_amount).toFixed(2)}
                  </span>
                </div>
              )}

              {parseFloat(details.order_subcharge_amount) > 0 && (
                <div className="cart_row">
                  <p className="text-uppercase">Surcharge</p>
                  <span>
                    {parseFloat(details.order_subcharge_amount).toFixed(2)}
                  </span>
                </div>
              )}

              {parseFloat(details.order_subcharge_amount) > 0 &&
                sur_deliverysetup_fee > 0 && (
                  <div className="cart_row">
                    <p className="text-uppercase">
                      Delivery & setup fee {sur_deliverysetup_fee_name}
                    </p>
                    <span>{showPriceValueNew(sur_deliverysetup_fee)}</span>
                  </div>
                )}

              {parseFloat(details.order_subcharge_amount) > 0 &&
                sur_ear_deliverysetup_fee > 0 && (
                  <div className="cart_row">
                    <p className="text-uppercase">
                      Early setup fee / Late setup fee{" "}
                      {sur_ear_deliverysetup_fee_name}
                    </p>
                    <span>{showPriceValueNew(sur_ear_deliverysetup_fee)}</span>
                  </div>
                )}

              {parseFloat(details.order_subcharge_amount) > 0 &&
                sur_lat_teardown_fee > 0 && (
                  <div className="cart_row">
                    <p className="text-uppercase">Late teardown fee</p>
                    <span>{showPriceValueNew(sur_lat_teardown_fee)}</span>
                  </div>
                )}

              {details.order_tax_calculate_amount > 0 && (
                <div className="cart_row gst-row">
                  <p className="text-uppercase">
                    GST({details.order_tax_charge}%)
                  </p>
                  <span>
                    {showPriceValueNew(details.order_tax_calculate_amount)}
                  </span>
                </div>
              )}
              {details.order_tax_calculate_amount_inclusive > 0 && (
                <div className="cart_row gst-row">
                  <p className="text-uppercase">
                    GST Inclusive({details.order_tax_charge_inclusive}%)
                  </p>
                  <span>
                    {showPriceValueNew(
                      details.order_tax_calculate_amount_inclusive
                    )}
                  </span>
                </div>
              )}

              <div className="cart_row grant-total-cls">
                <p className="text-uppercase">Total</p>
                <span>${details.order_total_amount}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
