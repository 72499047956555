/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import { connect } from "react-redux";
import Slider from "react-slick";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import HomeBanner from "./HomeSubCompo/HomeBanner";
import FeaturedProducts from "./HomeSubCompo/FeaturedProducts";
import qrcode from "../../common/images/dinein-qrcode.jpg";
import { callImage } from "../Helpers/SettingHelper";

import {
  appId,
  apiUrl,
  deliveryId,
  pickupId,
  cateringId,
  reservationId,
  dineinId,
  timThumpUrl,
} from "../Helpers/Config";
import {
  GET_GLOBAL_SETTINGS,
  GET_STATIC_BLOCK,
  GET_NORMAL_POPUP,
  GET_CATEGORY_LIST,
  GET_MENU_NAVIGATION,
  GET_TESTIMONIALS,
  GET_WHATS_HAPPENING,
} from "../../actions";
import {
  showLoader,
  hideLoader,
  validateEmailFun,
  stripslashes,
  showCustomAlert,
} from "../Helpers/SettingHelper";

import deliveryImg from "../../common/images/delivery-red.png";

import fourclmsImgone from "../../common/images/delivery-man.svg";
import fourclmsImgtwo from "../../common/images/bag.svg";
import fourclmsImgthree from "../../common/images/dish.svg";
import fourclmsImgfour from "../../common/images/calendar.svg";

import mapmarkericon from "../../common/images/mapmarker.png";
import clkicon from "../../common/images/clock.png";
import directionicon from "../../common/images/compass.png";
import takeawayrest from "../../common/images/takeawayrest.jpg";

import takeawayImg from "../../common/images/takeaway.png";

import dininImg from "../../common/images/rest.png";
import reservationImg from "../../common/images/calander.png";

var Parser = require("html-react-parser");

class Home extends Component {
  constructor(props) {
    super(props);

    var normalpopupFlg = "initial";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes" ||
      cookie.load("promoPopupTrigger") === "Yes"
    ) {
      normalpopupFlg = "trigger";
    }

    this.state = {
      users: [],
      testimonials: [],
      testimonialpath: [],
      whatshappening: [],
      whatshappeningpath: [],
      whatshappenings_source: "",
      whatshappenings: [],
      nextavail: "",
      cartTriggerFlg: "No",
      staticblacks: [],
      orderTypeBlks: "",
      doorStepBlck: "",
      ourStoryBlck: "",
      viewProductFlg: "no",
      viewProductSlug: "",
      normalpopuplist: [],
      normalpopupdata: [],
      normalpopup_status: "no",
      normalpopup_flg: normalpopupFlg,
      normalpopupstatus: "initial",
      promo_mail_id: "",
      displayType: "all",
    };

    this.props.getSettings();
    this.props.getCategoryList();
    this.props.getMenuNavigation();
    this.props.getNormalPopup();
    this.props.getStaticBlock();
    this.props.getTestimonials();
    this.props.getWhatsHappening();
  }

  componentWillReceiveProps(PropsData) {
    if (
      PropsData.whatshappening !== undefined &&
      PropsData.whatshappening !== this.state.whatshappening
    ) {
      this.setState({
        whatshappening: PropsData.whatshappening,
        whatshappeningpath: PropsData.whatshappeningpath,
      });
    }
    if (
      PropsData.testimonials !== undefined &&
      PropsData.testimonials !== this.state.testimonials
    ) {
      this.setState({
        testimonials: PropsData.testimonials,
        testimonialpath: PropsData.testimonialpath,
      });
    }

    if (PropsData.staticblack !== this.state.staticblacks) {
      var orderTypBlk = "",
        doorStepBlock = "",
        ourStoryBlock = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "order-type-block") {
            orderTypBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "door-step-block") {
            doorStepBlock = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "our-story-block") {
            ourStoryBlock = data.staticblocks_description;
            return "";
          }
        });
      }
      orderTypBlk =
        orderTypBlk !== "" && orderTypBlk !== null
          ? Parser(orderTypBlk)
          : orderTypBlk;
      doorStepBlock =
        doorStepBlock !== "" && doorStepBlock !== null
          ? Parser(doorStepBlock)
          : doorStepBlock;
      ourStoryBlock =
        ourStoryBlock !== "" && ourStoryBlock !== null
          ? Parser(ourStoryBlock)
          : ourStoryBlock;
      this.setState({
        staticblacks: PropsData.staticblack,
        orderTypeBlks: orderTypBlk,
        doorStepBlck: doorStepBlock,
        ourStoryBlck: ourStoryBlock,
      });
    }

    if (
      PropsData.normalpopuplist !== this.state.normalpopuplist &&
      this.state.normalpopup_flg === "initial"
    ) {
      var normalpopupMain = PropsData.normalpopuplist,
        normalpopupdata = [],
        normalpopupStatus = "no";
      if (normalpopupMain !== "") {
        if (Object.keys(normalpopupMain).length > 0) {
          var normalpopupIds = cookie.load("normalpopupIds");
          var normalpopupIdArr =
            normalpopupIds != "" && normalpopupIds != undefined
              ? normalpopupIds.split("~~")
              : Array();
          if (
            $.inArray(normalpopupMain[0].normalpopup_id, normalpopupIdArr) ===
            -1
          ) {
            normalpopupdata = normalpopupMain[0];
            normalpopupStatus = "yes";
          }
          this.setState({
            normalpopuplist: normalpopupMain,
            normalpopupdata: normalpopupdata,
            normalpopup_status: normalpopupStatus,
            normalpopup_flg: "trigger",
          });
        }
      }
    }

    if (PropsData.normalpopupstatus !== this.state.normalpopupstatus) {
      this.setState({ normalpopupstatus: PropsData.normalpopupstatus });
    }
  }

  chooseAvailability(availability) {
    var defaultAvilTy = cookie.load("defaultAvilablityId");
    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (defaultAvilTy === cateringId && cartTotalItems > 0) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      } else if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      popupIdtxt = "#delevery-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    } else if (availability === cateringId) {
      cookie.save("defaultAvilablityId", cateringId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/catering");
      return false;
    } else if (availability === reservationId) {
      cookie.save("defaultAvilablityId", reservationId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/reservation");
      return false;
      /* popupIdtxt = '#comingsoon-popup';*/
    } else if (availability === dineinId) {
      popupIdtxt = "#dinein-popup";
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  componentDidMount() {
    axios.get(apiUrl + "cms/whats_happening?app_id=" + appId).then((res) => {
      if (res.data.status === "ok") {
        let whatshappenings_source = res.data?.common?.image_source;
        let whatshappenings = res.data.result_set;

        this.setState({
          whatshappenings_source: whatshappenings_source,
          whatshappenings: whatshappenings,
        });
      } else if (res.data.status === "error") {
      }

      return false;
    });

    if ($(".open-popup-link").length > 0) {
      $(".open-popup-link").magnificPopup({
        type: "inline",
        midClick: true,
      });
    }

    if (
      cookie.load("triggerAvlPop") != "" &&
      cookie.load("triggerAvlPop") != undefined
    ) {
      var availabilityId = cookie.load("triggerAvlPop");
      cookie.remove("triggerAvlPop", { path: "/" });
      this.chooseAvailability(availabilityId);
    }

    if (cookie.load("orderPopuptrigger") === "Yes") {
      cookie.remove("orderPopuptrigger", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("loginpopupTrigger") === "Yes") {
      cookie.save("loginpopupTrigger", "fromcheckout", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#guest-popup",
        },
        type: "inline",
      });
    }

    var RctThis = this;
    $("body")
      .off("click", ".act_order_popup")
      .on("click", ".act_order_popup", function (e) {
        e.preventDefault();

        var odrType = $(this).attr("data-acturl");
        var availabilityId = "";
        if (odrType === "delivery") {
          availabilityId = deliveryId;
        } else if (odrType === "pickup") {
          availabilityId = pickupId;
        } else if (odrType === "catering") {
          availabilityId = cateringId;
        } else if (odrType === "reservation") {
          availabilityId = reservationId;
        }

        if (availabilityId !== "") {
          RctThis.chooseAvailability(availabilityId);
        } else if (availabilityId === "" && odrType === "ordernow") {
          $.magnificPopup.open({
            items: {
              src: "#order-popup",
            },
            type: "inline",
          });
        }
      });
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductFlg: "yes", viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
    if (field === "view_pro_upate" && value !== "") {
      this.setState({ viewProductFlg: value });
    }
    if (field === "firstcategory" && value !== "") {
      this.setState({ firstcategory: value });
    }
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    $("#ProductDetailMdl").modal({ backdrop: "static", keyboard: false });
  }

  triggerNormalPopup(trgType) {
    var otherPageActTrigger = "no";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes"
    ) {
      otherPageActTrigger = "yes";
    }

    if (
      trgType === "loading" &&
      otherPageActTrigger === "no" &&
      this.state.normalpopup_status === "yes" &&
      Object.keys(this.state.normalpopupdata).length > 0 &&
      cookie.load("promoPopupTrigger") !== "Yes"
    ) {
      var normalpopupIds = cookie.load("normalpopupIds");
      var normalpopupIdsNew =
        normalpopupIds != "" && normalpopupIds != undefined
          ? normalpopupIds + "~~" + this.state.normalpopupdata.normalpopup_id
          : this.state.normalpopupdata.normalpopup_id;
      var normalpopupIdArr = [];
      normalpopupIdArr["normalpopupids"] = normalpopupIdsNew;
      cookie.save("normalpopupIds", normalpopupIdsNew, { path: "/" });
      var $_this_rec = this;
      $.magnificPopup.open({
        items: {
          src: "#normal-popup",
        },
        type: "inline",
        midClick: true,
        closeOnBgClick: false,
        callbacks: {
          close: function () {
            $_this_rec.normalPopupUpdate();
          },
        },
      });
    }

    if (
      (cookie.load("promoPopupTrigger") === "Yes" ||
        (otherPageActTrigger === "no" &&
          this.state.normalpopup_status === "no" &&
          Object.keys(this.state.normalpopupdata).length === 0)) &&
      cookie.load("mailpopopuptrg") !== "yes"
    ) {
      /*cookie.save("mailpopopuptrg", "yes", { path: "/" });
      cookie.remove("promoPopupTrigger", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#promo-check-popup",
        },
        type: "inline",
        midClick: true,
        closeOnBgClick: false,
      });*/
    }
  }

  normalPopupUpdate() {
    if (cookie.load("mailpopopuptrg") !== "yes") {
      this.props.history.push("/refpage/promopopup");
    }
  }

  handleEmailChange(event) {
    this.setState({ promo_mail_id: event.target.value, promomail_error: "" });
  }

  sendPromoMailFun() {
    var promoEmail = this.state.promo_mail_id;

    var mailErrorTxt = "";
    if (promoEmail === "") {
      mailErrorTxt = "Email Address is required.";
    } else if (!validateEmailFun(promoEmail)) {
      mailErrorTxt = "Invalide Email Address";
    }

    if (mailErrorTxt !== "") {
      this.setState({ promomail_error: mailErrorTxt });
      return false;
    } else {
      showLoader("promomailpopup-cls", "class");

      var qs = require("qs");
      var postObject = {
        app_id: appId,
        email_address: promoEmail,
      };

      axios
        .post(apiUrl + "promotion/user_promotion", qs.stringify(postObject))
        .then((response) => {
          hideLoader("promomailpopup-cls", "class");
          $.magnificPopup.close();
          if (response.data.status === "ok") {
            showCustomAlert(
              "success",
              "You are now a Member of Nayana. Please check your email for more information."
            );
          } else {
            var errMsgtxt =
              response.data.message !== ""
                ? response.data.message
                : "Sorry! You didn`t have promo code.";
            showCustomAlert("error", errMsgtxt);
          }
          return false;
        });
    }

    return false;
  }

  setdisplayType(displayType) {
    this.setState({ displayType: displayType });
  }
  chooseAvailabilityFun(availability, event) {
    event.preventDefault();
    var defaultAvilTy = cookie.load("defaultAvilablityId");
    cookie.save("defaultAvilablityId", availability, { path: "/" });

    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (defaultAvilTy === cateringId && cartTotalItems > 0) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      } else if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    } else if (
      defaultAvilTy === availability &&
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
    ) {
      /*$.magnificPopup.close();
      this.props.history.push('/products');
      return false;*/
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      popupIdtxt = "#delevery-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    } else if (availability === cateringId) {
      cookie.save("defaultAvilablityId", cateringId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/");
      return false;
    } else if (availability === reservationId) {
      cookie.save("defaultAvilablityId", reservationId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/reservation");
      return false;
      /*popupIdtxt = '#comingsoon-popup';*/
    } else if (availability === dineinId) {
      popupIdtxt = "#dinein-popup";
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  checkActiveDivHd(avlType) {
    var clsTxt = "";
    var availability = cookie.load("defaultAvilablityId");
    if (availability == avlType) {
      clsTxt += "active";
    }
    return clsTxt;
  }

  listwhatshappenings() {
    var Happenings = {
      dots: true,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHeight: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    let whatshappenings = this.state.whatshappenings;
    let whatshappenings_source = this.state.whatshappenings_source;

    if (Object.keys(whatshappenings).length > 0) {
      const mainMenu = whatshappenings.map((loaddata, index) => {
        return (
          <div className="container-four" key={"wh" + (index + 1)}>
            <div className="our-promotions-section happening-section">
              <h2>{loaddata.wh_name}</h2>

              <Slider {...Happenings}>
                {loaddata.gallery_image.map((loaddatagall, gallindex) => {
                  return (
                    <div
                      key={"whgall" + index + "" + (gallindex + 1)}
                      onClick={() =>
                        this.setState({
                          photoIndex:
                            whatshappenings_source +
                            "/" +
                            loaddatagall.gallery_image,
                          isOpen: true,
                        })
                      }
                    >
                      <img
                        src={
                          this.state.whatshappeningpath.image_source +
                          loaddatagall.gallery_image
                        }
                      />
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        );
      });

      return mainMenu;
    } else {
      return null;
    }
  }

  render() {
    var stcLeftBnr = {
      infinite: true,
      arrows: false,
      dots: true,
      dotsClass: "custom-dots",
      slidesToShow: 1,
      autoplay: true,
      slidesToScroll: 1,
    };
    var Ourspecialdishes = {
      dots: true,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            adaptiveHeight: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    var Promoslide = {
      dots: true,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    var Testimonial = {
      centerMode: true,
      slidesToShow: 3,
      centerPadding: "0px",
      dots: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: "0px",
            slidesToShow: 1,
          },
        },
      ],
    };

    var Happenings = {
      dots: true,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHeight: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    let settingsArr = this.props.globalsettings;
    var showPromoPopup = "",
      showNormalPopup = "";
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        if (settingsArr[0].result_set.client_promocode_enable === "1") {
          showNormalPopup =
            settingsArr[0].result_set.client_promo_code_normal_popup_enable ===
            "1"
              ? "yes"
              : "";
          showPromoPopup =
            settingsArr[0].result_set.client_promocode_options === "1"
              ? "yes"
              : "";
        }
      }
    }

    return (
      <div className="home-main-div">
        {/*<div className="top-bar">
          <span>Important</span>
          <p>Pre-Orders Delivery : Lunch-By 10am / Dinner-By 3pm</p>
    </div>*/}
        {/* Header section */}
        <Header
          homePageState={this.state}
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />

        {/* Home banner section */}
        <div className="home-banner-main">
          <HomeBanner />
        </div>
        <section className="white-home">
          <div className="bann-container">
            <div className="home-top-four-section">
              <div className="four-columns">
                <img src={fourclmsImgone} />
                <h3>Delivery</h3>

                <a
                  className="four-columns-btn open-popup-link"
                  href="javscript:void(0)"
                  onClick={this.chooseAvailabilityFun.bind(this, deliveryId)}
                  data-effect="mfp-zoom-in"
                >
                  Order Now
                </a>
              </div>
              <div className="four-columns">
                <img src={fourclmsImgtwo} />
                <h3>Takeaway</h3>
                <a
                  className="four-columns-btn open-popup-link"
                  href="javscript:void(0)"
                  onClick={this.chooseAvailabilityFun.bind(this, pickupId)}
                  data-effect="mfp-zoom-in"
                >
                  Order Now
                </a>
              </div>
              <div className="four-columns">
                <img src={fourclmsImgthree} />
                <h3>Dine In</h3>
                <a
                  className="four-columns-btn"
                  href="javscript:void(0)"
                  onClick={this.chooseAvailabilityFun.bind(this, dineinId)}
                >
                  Order Now
                </a>
              </div>
              <div className="four-columns">
                <img src={fourclmsImgfour} />
                <h3>Reservation</h3>
                <a
                  className="four-columns-btn"
                  onClick={this.chooseAvailabilityFun.bind(this, reservationId)}
                  title="Order Now"
                  href="reservation"
                >
                  Book Now
                </a>
              </div>
            </div>

            {/* Order popup - start */}
            {/* <div
          id="order-popup"
          className="white-popup mfp-hide popup_sec order_popup"
        >
          <div className="order-body">
            <h2>Order Now</h2>
            <p>Select your order type</p>
            <div className="order_delivery_row">
              <div className="order_delivery_col">
                <ul className="order_delivery_item">
                  <li>
                    <a
                      href="/"
                      onClick={this.chooseAvailabilityFun.bind(
                        this,
                        deliveryId
                      )}
                      className={this.checkActiveDivHd(deliveryId)}
                    >
                      <div className="header-ordernow-single-img">
                        <img className="order_type_img" src={deliveryImg} />
                        <h3>Delivery</h3>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a
                      href="/"
                      onClick={this.chooseAvailabilityFun.bind(this, pickupId)}
                      className={this.checkActiveDivHd(pickupId)}
                    >
                      <div className="header-ordernow-single-img">
                        <img className="order_type_img" src={takeawayImg} />
                        <h3>Takeaway</h3>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a
                      href="/"
                      onClick={this.chooseAvailabilityFun.bind(this, pickupId)}
                      className={this.checkActiveDivHd(pickupId)}
                    >
                      <div className="header-ordernow-single-img">
                        <img className="order_type_img" src={dininImg} />
                        <h3>Dine In</h3>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a
                      href="/"
                      onClick={this.chooseAvailabilityFun.bind(this, pickupId)}
                      className={this.checkActiveDivHd(pickupId)}
                    >
                      <div className="header-ordernow-single-img">
                        <img className="order_type_img" src={reservationImg} />
                        <h3>Reservation</h3>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>*/}
            {/* Order popup - end */}

            {/* delivery popup */}

            <div
              id="delivery-popup"
              className="white-popup mfp-hide popup_sec login-popup"
            >
              <div className="pop-whole">
                <div className="inside-popup-rhs">
                  <div className="pop-whole-lhs-inner home-popup-head">
                    <img src={fourclmsImgone} />
                    <h3>Delivery</h3>
                    <p>Let us Know your delivery Location</p>
                  </div>
                  <div className="form-group">
                    <div className="focus-out">
                      <input type="text" className="form-control input-focus" />
                    </div>
                  </div>
                  <div className="form-group text-center">
                    <div className="custom_checkbox custom_checkbox_content">
                      <input type="checkbox" id="terms" />
                      <span></span>
                      <div className="sign_reg check-p">
                        <p>
                          Block 331, Jalan Rumah Tinggi
                          <a className="sign_reg_uline" href="terms-of-use"></a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group text-center date-sec row">
                    <p>Choose Date & Time</p>
                    <div className="col-md-6">
                      <div className="focus-out" id="datepicker">
                        <input
                          type="date"
                          max="3000-12-31"
                          min="1000-01-01"
                          className="form-control input-focus"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="focus-out">
                        <input
                          type="time"
                          className="form-control input-focus"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="takeaway-btn-part mb-20 mt-20">
                    <a className="btn" href="/">
                      Back
                    </a>
                    <a className="button " href="/">
                      Continue
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/*Take Away*/}
            <div
              id="takeaway-popup-body"
              className="white-popup mfp-hide popup_sec login-popup"
            >
              <div className="pop-whole">
                <div className="inside-popup-rhs">
                  <div className="pop-whole-lhs-inner home-popup-head">
                    <img src={fourclmsImgtwo} />
                    <h3>Takeaway</h3>
                    <p>Let us Know your delivery location</p>
                  </div>

                  <div className="form-group m-row">
                    <div className="focus-out">
                      <input
                        type="text"
                        placeholder="search outlet"
                        className="form-control br-5 input-focus"
                      />
                    </div>
                  </div>

                  <div className="row ta-box-inner mb-5">
                    <div className="col-md-3">
                      <img src={takeawayrest} width="175" className="rounded" />
                    </div>
                    <a>
                      <div className="col-md-9 ">
                        <div className="ta-header">
                          <h5 className="clr-blk">Outlet Name Will be Here</h5>
                          <p>Outlet Name Will be Here</p>
                          <div className="d-flex tkaw-icons">
                            <span>
                              {" "}
                              <img src={mapmarkericon} /> 5 km
                            </span>
                            <span>
                              <img src={clkicon} /> Waiting : 15 min
                            </span>
                            <span>
                              <img src={directionicon} /> Direction
                            </span>
                            <span className="f-right f-bold">
                              Operations : 10am - 6pm
                            </span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="row ta-box-inner mb-5">
                    <div className="col-md-3">
                      <img src={takeawayrest} width="175" className="rounded" />
                    </div>
                    <div className="col-md-9">
                      <div className="ta-header">
                        <h5>Outlet Name Will be Here</h5>
                        <p>Outlet Name Will be Here</p>
                        <div className="d-flex tkaw-icons">
                          <span>
                            {" "}
                            <img src={mapmarkericon} /> 5 km
                          </span>
                          <span>
                            <img src={clkicon} /> Waiting : 15 min
                          </span>
                          <span>
                            <img src={directionicon} /> Direction
                          </span>
                          <span className="f-right f-bold">
                            Operations : 10am - 6pm
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row ta-box-inner mb-20">
                    <div className="col-md-3">
                      <img src={takeawayrest} width="175" className="rounded" />
                    </div>
                    <div className="col-md-9">
                      <div className="ta-header">
                        <h5>Outlet Name Will be Here</h5>
                        <p>Outlet Name Will be Here</p>
                        <div className="d-flex tkaw-icons">
                          <span>
                            {" "}
                            <img src={mapmarkericon} /> 5 km
                          </span>
                          <span>
                            <img src={clkicon} /> Waiting : 15 min
                          </span>
                          <span>
                            <img src={directionicon} /> Direction
                          </span>
                          <span className="f-right f-bold">
                            Operations : 10am - 6pm
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-20">
                    <div className="order_delivery_col">
                      <ul className="takeaway-button">
                        <a className="tkaw-white-btn" href="#">
                          Back
                        </a>
                        <a className="four-columns-btn" href="#">
                          Continue
                        </a>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container common-top-div">
            <FeaturedProducts sateValChange={this.sateValChange} />

            {/*{Object.keys(this.state.whatshappening).length && <div className="container-four">
          <div className="our-promotions-section happening-section">
          <h2>Dine in Promotions</h2>
            <Slider {...Happenings}>
            {this.state.whatshappening.length > 0 && this.state.whatshappening[0].gallery_image.map((gallery, index) => {
              return ( <div>
              <img src={this.state.whatshappeningpath.image_source+gallery.gallery_image} />
              </div>  )})}
             
            </Slider> 
          </div>
        </div>}*/}
            {this.listwhatshappenings()}

            {Object.keys(this.state.testimonials).length && (
              <div className="testimonial-section">
                <h2>What They Said</h2>
                <Slider {...Testimonial}>
                  {Object.keys(this.state.testimonials).length &&
                    this.state.testimonials.map((testimonial, index) => {
                      return (
                        <div key={index + 1000}>
                          <div className="testi-content">
                            {" "}
                            <p>
                              {stripslashes(
                                testimonial.testimonial_description
                              )}
                            </p>
                          </div>
                          <div className="testi-author">
                            {/*<div className="testi-author-img"><img src={this.state.testimonialpath.image_source+testimonial.testimonial_image} /></div>*/}
                            <div className="testi-author-name">
                              <h4>- {testimonial.testimonial_tittle}</h4>
                              <span>{testimonial.testimonial_company}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </Slider>
              </div>
            )}

            {/*{Object.keys(this.state.whatshappening).length &&  <div className="container-four">
          <div className="our-promotions-section happening-section">
            <Slider {...Happenings}>
            {this.state.whatshappening.length > 0 && this.state.whatshappening[1].gallery_image.map((gallery, index) => {
              return ( <div>
              <img src={this.state.whatshappeningpath.image_source+gallery.gallery_image} />
              </div>  )})}
             
            </Slider>
          </div>
        </div>}*/}
          </div>
        </section>
        <section className="parallax outlet-bg">
          {/* <h1>Read Our Blog</h1>*/}
        </section>
        {/* Footer section */}
        <Footer />

        {/*Normal popup Start*/}
        <div
          id="normal-popup"
          className="white-popup mfp-hide popup_sec normal-popup"
        >
          <div className="normal_popup_alert">
            {this.state.normalpopup_status === "yes" &&
              Object.keys(this.state.normalpopupdata).length > 0 && (
                <div className="normal_popup_cont">
                  {Parser(this.state.normalpopupdata.normalpopup_description)}
                </div>
              )}
          </div>
        </div>
        {/*Normal popup - End*/}

        {this.state.normalpopupstatus !== "initial" &&
          (showNormalPopup === "yes" || showPromoPopup === "yes") &&
          this.triggerNormalPopup("loading", showNormalPopup, showPromoPopup)}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  var normalPopupStatus = "initial";
  var normalPopupArr = Array();
  if (Object.keys(state.normalpopuplist).length > 0) {
    if (state.normalpopuplist[0].status === "ok") {
      normalPopupArr = state.normalpopuplist[0].result_set;
      normalPopupStatus = "ok";
    } else {
      normalPopupStatus = "error";
    }
  }

  var tempArr = Array();
  var navigateRst = Array();
  var navigateCmn = Array();
  var testimonials = Array();
  var testimonialpath = Array();
  var whatshappening = Array();
  var whatshappeningpath = Array();

  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
      }
    }
  }
  if (Object.keys(state.testimonials).length > 0) {
    testimonials = state.testimonials[0].result_set;
    testimonialpath = state.testimonials[0].common;
  }
  if (Object.keys(state.whatshappening).length > 0) {
    whatshappening = state.whatshappening[0].result_set;
    whatshappeningpath = state.whatshappening[0].common;
  }

  return {
    globalsettings: state.settings,
    categorylist: state.categorylist,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    staticblack: blacksArr,
    normalpopuplist: normalPopupArr,
    normalpopupstatus: normalPopupStatus,
    testimonials: testimonials,
    testimonialpath: testimonialpath,
    whatshappening: whatshappening,
    whatshappeningpath: whatshappeningpath,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getCategoryList: () => {
      dispatch({ type: GET_CATEGORY_LIST });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getNormalPopup: () => {
      dispatch({ type: GET_NORMAL_POPUP });
    },
    getMenuNavigation: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
    getTestimonials: () => {
      dispatch({ type: GET_TESTIMONIALS });
    },
    getWhatsHappening: () => {
      dispatch({ type: GET_WHATS_HAPPENING });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
