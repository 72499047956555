import { SET_WHATS_HAPPENING } from '../actions';

const whatshappening = (state = [], action) => {
  switch (action.type) {
	case SET_WHATS_HAPPENING:
      return [...action.value];  
    default: return state;
  }
}

export default whatshappening;
